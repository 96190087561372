export const DATA = 
  {
    v: "5.5.10",
    fr: 25,
    ip: 0,
    op: 75,
    w: 1080,
    h: 1080,
    nm: "dragon_lottie",
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: "line",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [756, 793, 0], ix: 2 },
          a: { a: 0, k: [268, 73, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [268, -206],
                      [268, 352],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.078431372549, 0.258823529412, 0.619607843137, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "tm",
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.35], y: [1] },
                  o: { x: [0.65], y: [0] },
                  t: 64,
                  s: [0],
                },
                { t: 75, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 58,
                  s: [0],
                },
                { t: 70, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: "Trim Paths 1",
            mn: "ADBE Vector Filter - Trim",
            hd: false,
          },
        ],
        ip: 58,
        op: 76,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 3,
        nm: "Null 1",
        sr: 1,
        ks: {
          o: { a: 0, k: 0, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.35, y: 1 },
                o: { x: 0.65, y: 0 },
                t: 0,
                s: [540, 1629, 0],
                to: [0, -171.833, 0],
                ti: [0, 181.5, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.339, y: 0 },
                t: 20,
                s: [540, 598, 0],
                to: [0, -0.02, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 21,
                s: [540, 597.941, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.664, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 63,
                s: [540, 539.951, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.35, y: 1 },
                o: { x: 0.65, y: 0 },
                t: 64,
                s: [540, 540, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 75, s: [540, -174, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        ip: 0,
        op: 250,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: "rocket",
        parent: 2,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 0,
                s: [0, 0, 0],
                to: [0, 0.333, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 5,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, -0.333, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 9,
                s: [0, 0, 0],
                to: [0, 0.233, 0],
                ti: [0, -1.099, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 13,
                s: [0, 2, 0],
                to: [0, 0.471, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 18,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 19,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 20,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 21,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 22,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 23,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 24,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 25,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 26,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 27,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 28,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 29,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 30,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 31,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 32,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 33,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 34,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 35,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 36,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 37,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 38,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 39,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 40,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 41,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 42,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 43,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 44,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 45,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 46,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 47,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 48,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 49,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 50,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 51,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 52,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 53,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 54,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 55,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 56,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 57,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 58,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 59,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 60,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 61,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 62,
                s: [0, 0, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 63,
                s: [0, 2, 0],
                to: [0, 0, 0],
                ti: [0, 0.333, 0],
              },
              { t: 64, s: [0, 0, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [540, 540, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.94, -0.98],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-4.343, 0.101],
                      [0, 0],
                    ],
                    v: [
                      [-7.263, 6.717],
                      [4.304, 5.489],
                      [7.263, -6.717],
                      [-4.519, -4.667],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.078000005086, 0.490000017952, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [459.696, 207.511], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 1",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.211, 0.048],
                      [0.091, -0.01],
                      [-0.026, -0.215],
                      [-0.215, 0.027],
                      [-10.642, -2.415],
                      [-0.029, 0],
                      [-0.042, 0.182],
                    ],
                    o: [
                      [-10.775, -2.444],
                      [-0.215, 0.027],
                      [0.027, 0.215],
                      [0.089, -0.012],
                      [0.029, 0.006],
                      [0.179, 0],
                      [0.048, -0.212],
                    ],
                    v: [
                      [10.021, 0.834],
                      [-9.997, -0.51],
                      [-10.339, -0.071],
                      [-9.901, 0.271],
                      [9.847, 1.601],
                      [9.935, 1.609],
                      [10.317, 1.305],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 4,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.877999997606, 0.027000000898, 0.411999990426, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [475.008, 205.7], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 2",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.211, 0.047],
                      [0.097, -0.013],
                      [-0.029, -0.215],
                      [-0.214, 0.03],
                      [-9.568, -2.147],
                      [-0.029, 0],
                      [-0.041, 0.182],
                    ],
                    o: [
                      [-9.705, -2.177],
                      [-0.215, 0.029],
                      [0.03, 0.215],
                      [0.096, -0.013],
                      [0.029, 0.006],
                      [0.179, 0],
                      [0.047, -0.212],
                    ],
                    v: [
                      [9.778, 0.701],
                      [-9.756, -0.188],
                      [-10.093, 0.254],
                      [-9.651, 0.59],
                      [9.606, 1.467],
                      [9.693, 1.476],
                      [10.075, 1.17],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 4,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.877999997606, 0.027000000898, 0.411999990426, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [474.542, 209.178], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 3",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 3,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.212, 0.044],
                      [0.116, -0.01],
                      [-0.017, -0.215],
                      [-0.217, 0.017],
                      [-9.567, -2.015],
                      [-0.026, 0],
                      [-0.039, 0.185],
                    ],
                    o: [
                      [-9.677, -2.038],
                      [-0.217, 0.017],
                      [0.018, 0.217],
                      [0.115, -0.009],
                      [0.028, 0.006],
                      [0.182, 0],
                      [0.045, -0.212],
                    ],
                    v: [
                      [10.688, 0.631],
                      [-10.657, -0.518],
                      [-11.018, -0.097],
                      [-10.596, 0.264],
                      [10.526, 1.399],
                      [10.607, 1.407],
                      [10.991, 1.096],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 4,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.877999997606, 0.027000000898, 0.411999990426, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [472.618, 212.458], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 4",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 4,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.214, 0.032],
                      [0.148, -0.028],
                      [-0.04, -0.214],
                      [-0.185, 0],
                      [-0.024, 0.004],
                      [-16.314, -2.464],
                      [-0.033, 0.214],
                    ],
                    o: [
                      [-16.444, -2.484],
                      [-0.213, 0.04],
                      [0.035, 0.188],
                      [0.024, 0],
                      [0.147, -0.028],
                      [0.215, 0.031],
                      [0.032, -0.215],
                    ],
                    v: [
                      [15.694, 0.723],
                      [-15.703, 0.982],
                      [-16.016, 1.44],
                      [-15.63, 1.76],
                      [-15.557, 1.753],
                      [15.577, 1.499],
                      [16.023, 1.17],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 4,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.877999997606, 0.027000000898, 0.411999990426, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [467.22, 215.564], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 5",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 5,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.213, 0.04],
                      [0.149, -0.028],
                      [-0.039, -0.213],
                      [-0.212, 0.04],
                      [-16.119, -3.025],
                      [-0.024, 0],
                      [-0.035, 0.188],
                    ],
                    o: [
                      [-16.262, -3.052],
                      [-0.213, 0.04],
                      [0.04, 0.213],
                      [0.147, -0.027],
                      [0.024, 0.005],
                      [0.185, 0],
                      [0.04, -0.213],
                    ],
                    v: [
                      [15.644, 1.137],
                      [-15.643, 0.827],
                      [-15.957, 1.285],
                      [-15.499, 1.598],
                      [15.499, 1.908],
                      [15.572, 1.915],
                      [15.957, 1.595],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 4,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.877999997606, 0.027000000898, 0.411999990426, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [466.516, 218.483], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 6",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 6,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.213, 0.04],
                      [0.15, -0.024],
                      [-0.034, -0.214],
                      [-0.215, 0.033],
                      [-16.118, -3.025],
                      [-0.024, 0],
                      [-0.035, 0.188],
                    ],
                    o: [
                      [-16.257, -3.051],
                      [-0.214, 0.034],
                      [0.035, 0.214],
                      [0.147, -0.025],
                      [0.024, 0.005],
                      [0.185, 0],
                      [0.04, -0.213],
                    ],
                    v: [
                      [15.621, 1.136],
                      [-15.759, 0.463],
                      [-16.083, 0.912],
                      [-15.633, 1.237],
                      [15.475, 1.908],
                      [15.548, 1.914],
                      [15.934, 1.594],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 4,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.877999997606, 0.027000000898, 0.411999990426, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [466.026, 221.301], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 7",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 7,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.212, 0.043],
                      [0.104, -0.012],
                      [-0.024, -0.216],
                      [-0.215, 0.025],
                      [-10.57, -2.159],
                      [-0.026, 0],
                      [-0.038, 0.186],
                    ],
                    o: [
                      [-10.696, -2.185],
                      [-0.215, 0.024],
                      [0.025, 0.215],
                      [0.102, -0.012],
                      [0.026, 0.005],
                      [0.182, 0],
                      [0.043, -0.212],
                    ],
                    v: [
                      [10.636, 0.704],
                      [-10.572, -0.32],
                      [-10.917, 0.115],
                      [-10.482, 0.46],
                      [10.479, 1.473],
                      [10.558, 1.481],
                      [10.942, 1.167],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 4,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.877999997606, 0.027000000898, 0.411999990426, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [474.973, 203.056], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 8",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 8,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [11.014, -4.098],
                      [0, 0],
                      [-8.709, -0.256],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [8.709, 0.257],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-12.807, -8.581],
                      [-17.93, 12.679],
                      [-3.074, 11.654],
                      [13.32, 12.679],
                      [17.93, -9.093],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [467.983, 211.424], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 16",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 9,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.192, 2.626],
                      [0.033, 0.234],
                      [5.892, 13.319],
                      [3.842, 8.709],
                      [6.148, 8.709],
                      [0.842, 1.15],
                      [0.985, 2.14],
                      [-4.934, -21.455],
                      [0, 0],
                      [-0.023, 0.258],
                      [-0.009, 0.111],
                      [-0.018, 0.209],
                      [-0.072, 0.809],
                      [-0.002, 0.022],
                      [-0.051, 0.581],
                      [-0.075, 0.844],
                      [-0.094, 1.066],
                      [-0.085, 0.964],
                      [-0.046, 0.524],
                      [-0.035, 0.392],
                      [-0.018, 0.211],
                      [-0.034, 0.384],
                      [-0.052, 0.593],
                      [-0.045, 0.519],
                      [-0.094, 1.057],
                      [-0.004, 0.041],
                      [-0.021, 0.24],
                      [-0.01, 0.113],
                      [-0.02, 0.224],
                      [-0.013, 0.146],
                      [-0.054, 0.5],
                      [-0.12, 0.842],
                    ],
                    o: [
                      [-0.017, -0.229],
                      [-0.512, -3.587],
                      [-5.891, -13.32],
                      [-3.842, -8.709],
                      [-0.615, -0.871],
                      [-3.368, -3.494],
                      [14.774, 52.743],
                      [0, 0],
                      [0.024, -0.259],
                      [0.01, -0.11],
                      [0.019, -0.209],
                      [0.074, -0.818],
                      [0.002, -0.022],
                      [0.052, -0.588],
                      [0.077, -0.866],
                      [0.098, -1.112],
                      [0.091, -1.017],
                      [0.048, -0.544],
                      [0.036, -0.404],
                      [0.019, -0.215],
                      [0.035, -0.398],
                      [0.056, -0.636],
                      [0.05, -0.562],
                      [0.126, -1.423],
                      [0.003, -0.042],
                      [0.023, -0.268],
                      [0.01, -0.12],
                      [0.023, -0.265],
                      [0.015, -0.17],
                      [0.048, -0.543],
                      [0.106, -0.999],
                      [0.715, -5.032],
                    ],
                    v: [
                      [39.766, 61.758],
                      [39.692, 61.064],
                      [22.53, 14.958],
                      [-7.695, -50.103],
                      [-31.005, -93.905],
                      [-33.203, -96.96],
                      [-39.957, -105.43],
                      [35.304, 105.43],
                      [35.32, 105.247],
                      [35.39, 104.472],
                      [35.419, 104.14],
                      [35.475, 103.514],
                      [35.694, 101.072],
                      [35.7, 101.006],
                      [35.856, 99.253],
                      [36.085, 96.688],
                      [36.375, 93.419],
                      [36.639, 90.443],
                      [36.781, 88.842],
                      [36.887, 87.648],
                      [36.943, 87.008],
                      [37.047, 85.835],
                      [37.21, 83.99],
                      [37.353, 82.367],
                      [37.683, 78.633],
                      [37.694, 78.509],
                      [37.761, 77.744],
                      [37.792, 77.393],
                      [37.857, 76.654],
                      [37.899, 76.176],
                      [38.052, 74.611],
                      [38.394, 71.855],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.941000007181, 0.964999988032, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [615.791, 190.959], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 17",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 10,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-10.988, -2.577],
                      [-7.172, -0.768],
                      [-11.014, 0.768],
                      [-7.94, 0.512],
                      [-7.214, 4.076],
                      [5.761, 8.162],
                      [16.138, 4.354],
                      [20.748, -30.481],
                      [5.276, -10.474],
                    ],
                    o: [
                      [20.748, 4.867],
                      [7.172, 0.768],
                      [11.014, -0.769],
                      [6.527, -0.422],
                      [-4.655, -10.113],
                      [-6.148, -8.709],
                      [-16.137, -4.355],
                      [-10.129, 14.882],
                      [1.215, 1.99],
                    ],
                    v: [
                      [-58.271, 32.274],
                      [-3.2, 40.727],
                      [26.257, 42.264],
                      [54.945, 43.801],
                      [74.349, 39.398],
                      [51.872, -2.562],
                      [11.4, -39.959],
                      [-51.868, -12.808],
                      [-74.349, 24.986],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.941000007181, 0.964999988032, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [532.915, 99.616], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 18",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 11,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-2.13, -0.679],
                      [2.527, 4.288],
                      [3.234, -1.726],
                    ],
                    o: [
                      [0, 0],
                      [2.13, 0.679],
                      [-2.528, -4.289],
                      [-3.236, 1.727],
                    ],
                    v: [
                      [-2.97, 1.148],
                      [3.817, 6.794],
                      [5.351, 2.059],
                      [-4.642, -5.747],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.144999994016, 0.161000001197, 0.187999994615, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [622.615, 250.827], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 19",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 12,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.869, -4.671],
                      [-1.538, -3.843],
                      [-0.513, 3.329],
                    ],
                    o: [
                      [-1.427, 2.323],
                      [2.339, 5.849],
                      [0.512, -3.33],
                    ],
                    v: [
                      [-1.281, -5.328],
                      [-2.305, 4.15],
                      [3.33, 1.076],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.144999994016, 0.161000001197, 0.187999994615, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [630.38, 267.853], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 20",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 13,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.512, -5.892],
                      [-3.842, -1.28],
                      [1.793, 3.843],
                    ],
                    o: [
                      [0, 0],
                      [3.842, 1.281],
                      [-1.793, -3.842],
                    ],
                    v: [
                      [-3.586, -4.354],
                      [0.256, 8.965],
                      [-0.769, -5.123],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.144999994016, 0.161000001197, 0.187999994615, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [640.37, 289.933], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 21",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 14,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.528, 0],
                      [-0.862, -0.981],
                      [0.247, -0.987],
                      [-3.577, -4.209],
                      [-0.876, 0.074],
                      [-0.419, 0.574],
                      [1.909, 8.266],
                      [0.189, 0.832],
                      [0.165, 1.157],
                      [3.828, 5.932],
                      [0.782, 1.257],
                      [4.995, -0.801],
                      [0.336, -0.951],
                      [-1.75, -1.944],
                      [-1.233, -0.997],
                      [0.212, -2.127],
                      [0.626, -0.844],
                      [0.426, -3.126],
                      [-1.692, -1.723],
                      [-1.068, 0.125],
                    ],
                    o: [
                      [2.043, 0],
                      [0.637, 0.724],
                      [-0.339, 1.358],
                      [0.804, 0.947],
                      [0.763, -0.064],
                      [2.223, -3.048],
                      [-0.198, -0.852],
                      [-0.245, -1.079],
                      [-0.532, -3.706],
                      [-0.72, -1.116],
                      [-4.531, -7.287],
                      [-1.412, 0.225],
                      [-0.788, 2.23],
                      [0.728, 0.809],
                      [2.819, 2.281],
                      [-0.127, 1.265],
                      [-0.852, 1.148],
                      [-0.509, 3.73],
                      [0.782, 0.797],
                      [0.591, -0.07],
                    ],
                    v: [
                      [-0.391, 7.936],
                      [4.068, 9.431],
                      [4.673, 12.084],
                      [9.035, 29.28],
                      [11.531, 30.579],
                      [13.263, 29.644],
                      [11.413, 8.962],
                      [10.831, 6.433],
                      [10.264, 3.105],
                      [5.302, -10.733],
                      [3.061, -14.296],
                      [-12.101, -29.851],
                      [-14.699, -28.103],
                      [-11.641, -19.882],
                      [-8.517, -17.133],
                      [-3.247, -10.569],
                      [-4.592, -7.663],
                      [-6.837, -2.088],
                      [-4.859, 7.029],
                      [-2.071, 8.04],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.243000000598, 0.26699999641, 0.298000021542, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [629.798, 272.404], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 22",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 15,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-13.206, 17.598],
                      [10.504, -15.432],
                      [5.891, -14.088],
                      [5.891, -15.625],
                      [0.256, -7.941],
                      [-1.025, -9.733],
                      [0, 0],
                      [-1.793, -0.512],
                      [-1.272, -0.002],
                      [-10.938, 34.538],
                    ],
                    o: [
                      [-11.332, 3.456],
                      [-20.747, 30.481],
                      [-5.892, 14.089],
                      [-5.892, 15.625],
                      [-0.256, 7.941],
                      [1.024, 9.734],
                      [0, 0],
                      [0.98, 0.28],
                      [1.294, -18.752],
                      [17.667, -55.782],
                    ],
                    v: [
                      [55.698, -139.134],
                      [21.914, -112.367],
                      [-12.665, -47.563],
                      [-45.452, 25.439],
                      [-55.442, 56.689],
                      [-54.417, 97.416],
                      [-52.624, 129.947],
                      [-50.319, 138.656],
                      [-46.854, 139.134],
                      [-35.493, 39.159],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.941000007181, 0.964999988032, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [459.133, 199.176], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 23",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 16,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [13.576, -34.58],
                      [-3.33, 7.941],
                      [-9.99, 0.257],
                      [-3.329, -2.05],
                      [0, 0],
                      [2.818, 0],
                    ],
                    o: [
                      [0, 0],
                      [3.33, -7.94],
                      [9.99, -0.256],
                      [3.33, 2.049],
                      [0, 0],
                      [-4.61, 0],
                    ],
                    v: [
                      [-22.669, 23.438],
                      [-13.192, -11.398],
                      [1.921, -23.181],
                      [18.57, -21.131],
                      [22.669, -17.033],
                      [4.482, -17.289],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.144999994016, 0.161000001197, 0.187999994615, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [453.768, 281.096], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 24",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 17,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-2.338, -1.299],
                      [-4.355, 0],
                      [0, 0],
                      [-1.301, 6.915],
                    ],
                    o: [
                      [0.51, 2.057],
                      [6.916, 3.842],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-130.18, -4.483],
                      [-126.137, 0.641],
                      [-109.232, 2.434],
                      [121.555, 2.434],
                      [130.18, -4.483],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.144999994016, 0.161000001197, 0.187999994615, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [529.828, 351.793], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 25",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 18,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.281, 11.014],
                      [3.834, 14.024],
                      [0.399, -2.926],
                      [0, 0],
                      [0, 0],
                      [5.123, 4.098],
                      [2.049, 7.172],
                      [4.355, 15.625],
                      [4.099, 1.281],
                      [6.917, -1.025],
                      [4.866, -5.892],
                      [1.793, -13.832],
                      [1.024, -5.635],
                      [2.306, -0.768],
                      [1.794, 0.512],
                      [0, 0],
                      [0.464, 8.926],
                      [0.785, -4.555],
                      [-6.916, -3.842],
                      [-4.354, 0],
                      [0, 0],
                    ],
                    o: [
                      [-0.957, -8.23],
                      [-1.395, 15.505],
                      [-0.768, 5.635],
                      [0, 0],
                      [-5.123, 0],
                      [-5.123, -4.099],
                      [-2.049, -7.172],
                      [-4.354, -15.625],
                      [-4.098, -1.28],
                      [-6.916, 1.024],
                      [-4.867, 5.891],
                      [-1.793, 13.832],
                      [-1.025, 5.636],
                      [-2.305, 0.769],
                      [-1.793, -0.512],
                      [0, 0],
                      [-1.608, 7.089],
                      [-1.281, 7.428],
                      [6.916, 3.842],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [131.018, 42.648],
                      [121.695, -0.564],
                      [118.467, 33.428],
                      [112.576, 38.551],
                      [-5.764, 38.551],
                      [-21.389, 34.709],
                      [-29.842, 14.985],
                      [-42.649, -31.891],
                      [-55.457, -53.407],
                      [-76.461, -54.686],
                      [-90.548, -46.746],
                      [-99.257, -16.266],
                      [-106.173, 22.157],
                      [-113.602, 37.27],
                      [-120.262, 37.27],
                      [-122.567, 28.561],
                      [-123.323, 10.97],
                      [-127.945, 32.658],
                      [-125.384, 51.869],
                      [-108.479, 53.663],
                      [122.309, 53.663],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.243000000598, 0.26699999641, 0.298000021542, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [529.075, 300.563], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 26",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 19,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.47, 0.211],
                      [-0.422, 4.933],
                      [-2.47, -0.212],
                      [0.422, -4.933],
                    ],
                    o: [
                      [-2.469, -0.211],
                      [0.422, -4.934],
                      [2.47, 0.211],
                      [-0.423, 4.934],
                    ],
                    v: [
                      [-0.765, 8.933],
                      [-4.472, -0.382],
                      [0.765, -8.932],
                      [4.472, 0.383],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.144999994016, 0.161000001197, 0.187999994615, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [510.376, 289.164], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 27",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 20,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.89, -2.19],
                      [3.799, 3.278],
                      [-1.89, 2.19],
                      [-3.798, -3.278],
                    ],
                    o: [
                      [-1.89, 2.19],
                      [-3.799, -3.278],
                      [1.89, -2.191],
                      [3.799, 3.277],
                    ],
                    v: [
                      [6.879, 5.935],
                      [-3.422, 3.966],
                      [-6.878, -5.935],
                      [3.422, -3.965],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.144999994016, 0.161000001197, 0.187999994615, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [518.692, 267.146], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 28",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 21,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.715, -2.169],
                      [4.981, -3.94],
                      [1.715, 2.169],
                      [-4.98, 3.94],
                    ],
                    o: [
                      [1.715, 2.168],
                      [-4.981, 3.939],
                      [-1.715, -2.169],
                      [4.981, -3.939],
                    ],
                    v: [
                      [9.019, -7.133],
                      [3.105, 3.927],
                      [-9.019, 7.133],
                      [-3.106, -3.927],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.144999994016, 0.161000001197, 0.187999994615, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [525.563, 251.271], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 29",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 22,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0.704, -3.99],
                      [0.409, -7.177],
                      [0.204, -2.037],
                      [-1.81, -2.072],
                      [-2.068, -0.071],
                      [-1.474, 1.425],
                      [0, 3.398],
                      [-7.822, 0],
                      [-1.071, 1.856],
                      [1.178, 2.036],
                      [0.609, 0.865],
                      [-3.794, 4.687],
                      [-0.598, 0.703],
                      [2.795, 4.591],
                      [1.512, 0.217],
                      [4.521, -4.127],
                      [0.015, -0.015],
                    ],
                    o: [
                      [-0.058, 0.049],
                      [-0.458, 2.592],
                      [-0.261, 4.6],
                      [-0.365, 3.649],
                      [0.994, 1.136],
                      [2.095, 0.073],
                      [2.625, -2.535],
                      [0, -5.273],
                      [3.227, 0],
                      [0.906, -1.572],
                      [-0.587, -1.013],
                      [-2.256, -3.208],
                      [0.609, -0.751],
                      [3.657, -4.299],
                      [-0.712, -1.17],
                      [-4.572, -0.654],
                      [-5.797, 5.293],
                      [0, 0],
                    ],
                    v: [
                      [-11.861, -15.951],
                      [-15.694, -9.242],
                      [-17.023, 8.663],
                      [-17.735, 19.645],
                      [-16.378, 29.027],
                      [-11.829, 30.821],
                      [-6.451, 28.783],
                      [-2.652, 18.286],
                      [5.818, 8.28],
                      [12.483, 5.401],
                      [12.055, -0.276],
                      [10.229, -3.049],
                      [9.818, -13.714],
                      [11.643, -15.895],
                      [15.393, -28.18],
                      [12.089, -30.24],
                      [-4.411, -22.887],
                      [-11.814, -15.995],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.243000000598, 0.26699999641, 0.298000021542, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [521.336, 271.391], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 30",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 23,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-1.793, -0.512],
                      [-2.305, 0.769],
                      [-1.025, 5.635],
                      [-1.793, 13.832],
                      [-4.867, 5.891],
                      [-6.916, 1.025],
                      [-4.098, -1.281],
                      [-4.355, -15.625],
                      [-2.049, -7.172],
                      [-5.123, -4.098],
                      [-5.123, 0],
                      [0, 0],
                      [-0.768, 5.635],
                      [-0.769, 8.709],
                      [0.512, 3.586],
                      [5.892, 13.32],
                      [3.843, 8.709],
                      [6.147, 8.709],
                      [16.137, 4.354],
                      [20.748, -30.482],
                      [5.891, -14.088],
                      [5.891, -15.625],
                      [0.255, -7.941],
                      [-1.024, -9.733],
                    ],
                    o: [
                      [0, 0],
                      [1.794, 0.512],
                      [2.306, -0.769],
                      [1.024, -5.635],
                      [1.793, -13.832],
                      [4.866, -5.892],
                      [6.916, -1.024],
                      [4.099, 1.281],
                      [4.354, 15.625],
                      [2.049, 7.172],
                      [5.123, 4.099],
                      [0, 0],
                      [0, 0],
                      [0.768, -5.636],
                      [0.768, -8.708],
                      [-0.513, -3.586],
                      [-5.891, -13.319],
                      [-3.842, -8.709],
                      [-6.148, -8.709],
                      [-16.138, -4.355],
                      [-20.748, 30.481],
                      [-5.892, 14.089],
                      [-5.891, 15.625],
                      [-0.257, 7.941],
                      [1.024, 9.734],
                    ],
                    v: [
                      [-123.206, 131.659],
                      [-120.901, 140.369],
                      [-114.241, 140.369],
                      [-106.813, 125.255],
                      [-99.896, 86.833],
                      [-91.188, 56.353],
                      [-77.1, 48.411],
                      [-56.096, 49.693],
                      [-43.288, 71.208],
                      [-30.482, 118.083],
                      [-22.029, 137.807],
                      [-6.404, 141.65],
                      [111.937, 141.65],
                      [117.828, 136.526],
                      [123.976, 69.672],
                      [125.768, 54.56],
                      [108.605, 8.453],
                      [78.38, -56.609],
                      [55.071, -100.409],
                      [14.601, -137.807],
                      [-48.667, -110.654],
                      [-83.247, -45.85],
                      [-116.034, 27.151],
                      [-126.023, 58.401],
                      [-125, 99.129],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [529.715, 197.464], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 31",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 24,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 16.957],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.845, 0.03],
                      [-0.101, 19.466],
                      [0, 0],
                      [2.053, 5.244],
                    ],
                    o: [
                      [-6.182, -15.79],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.853, -0.028],
                      [19.454, -0.681],
                      [0, 0],
                      [0, -5.631],
                      [0, 0],
                    ],
                    v: [
                      [13.751, 6.471],
                      [4.396, -43.082],
                      [4.396, -128.846],
                      [-19.339, -128.846],
                      [-18.539, 128.846],
                      [-15.992, 128.759],
                      [19.01, 92.693],
                      [19.339, 29.265],
                      [16.232, 12.808],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.046999998654, 0.204000001795, 0.458999992819, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [590.55, 487.17], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 32",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 25,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [118.024, 3.778],
                      [-118.024, 3.778],
                      [-118.024, -3.778],
                      [118.024, -3.778],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.046999998654, 0.204000001795, 0.458999992819, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [533.872, 355.699], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 33",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 26,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-4.154, 1.662],
                      [0, 13.064],
                      [-0.075, 16.365],
                      [3.705, 10.95],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [12.273, -4.909],
                      [0, -8.114],
                      [0.053, -11.56],
                      [0, 0],
                    ],
                    v: [
                      [-11.384, -78.958],
                      [-11.384, 78.572],
                      [-0.917, 77.297],
                      [11.157, 44.158],
                      [11.331, 5.857],
                      [5.814, -28.138],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.046999998654, 0.204000001795, 0.458999992819, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [663.28, 505.93], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 34",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 27,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-24.846, 0.769],
                      [0, 0],
                      [-27.92, 0.256],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [24.846, -0.767],
                      [0, 0],
                      [27.92, -0.256],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-77.74, -47.515],
                      [-77.74, 31.378],
                      [-50.845, 45.465],
                      [-9.093, 18.571],
                      [36.245, 47.259],
                      [77.74, 27.791],
                      [77.74, -47.515],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 2",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 4,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "gf",
                o: { a: 0, k: 100, ix: 10 },
                r: 1,
                bm: 0,
                g: {
                  p: 3,
                  k: {
                    a: 0,
                    k: [
                      0, 0.078, 0.259, 0.62, 0.5, 0.063, 0.231, 0.539, 1, 0.047,
                      0.204, 0.459,
                    ],
                    ix: 9,
                  },
                },
                s: { a: 0, k: [-63, 0], ix: 5 },
                e: { a: 0, k: [63, -3], ix: 6 },
                t: 1,
                nm: "Gradient Fill 1",
                mn: "ADBE Vector Graphic - G-Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [497.47, 399.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 35",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 28,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-54.683, 0],
                      [-33.043, 4.611],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [27.665, 0],
                      [33.042, -4.61],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [115.906, -132.9],
                      [-115.906, -132.9],
                      [-115.906, -93.71],
                      [-115.906, 109.159],
                      [-50.284, 132.132],
                      [78.664, 128.289],
                      [115.906, 99.681],
                      [115.906, -57.849],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.078000005086, 0.258999992819, 0.620000023935, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [535.99, 484.82], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 36",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 29,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 16.257],
                      [0, 0],
                      [-2.082, 5.744],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-16.256, 0],
                      [0, 0],
                      [0, -6.109],
                      [0, 0],
                    ],
                    v: [
                      [20.748, -101.434],
                      [20.748, 101.434],
                      [8.688, 101.434],
                      [-20.748, 71.998],
                      [-20.748, 22.297],
                      [-17.601, 4.382],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.046999998654, 0.204000001795, 0.458999992819, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [400.336, 492.545], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Group 37",
            np: 2,
            cix: 2,
            bm: 0,
            ix: 30,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 0,
        op: 250,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: "ellipse 28",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 43,
                s: [504, 945.5, 0],
                to: [141, 1.667, 0],
                ti: [-163.393, 59.091, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 63,
                s: [906, 1117.5, 0],
                to: [310.232, -112.194, 0],
                ti: [-88.167, 215.833, 0],
              },
              { t: 82, s: [1283, 730.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 43,
                s: [0, 0, 100],
              },
              { t: 53, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 43,
        op: 83,
        st: 43,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: "ellipse 27",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 47,
                s: [526, 603.5, 0],
                to: [-1, 175.667, 0],
                ti: [-151.601, -84.889, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 67,
                s: [734, 1107.5, 0],
                to: [78.232, 43.806, 0],
                ti: [-119.833, 33.167, 0],
              },
              { t: 86, s: [1119, 1172.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 47,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 57,
                s: [50, 50, 100],
              },
              { t: 70, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 47,
        op: 87,
        st: 47,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 4,
        nm: "ellipse 26",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 43,
                s: [508, 745.5, 0],
                to: [5, 103.667, 0],
                ti: [-101.901, -35.007, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 63,
                s: [714, 1099.5, 0],
                to: [182.033, 62.535, 0],
                ti: [-18.108, 150.248, 0],
              },
              { t: 82, s: [1259, 1018.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 43,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 53,
                s: [99, 99, 100],
              },
              { t: 82, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 43,
        op: 83,
        st: 43,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 4,
        nm: "ellipse 25",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 47,
                s: [542, 617.5, 0],
                to: [-10.755, 107.393, 0],
                ti: [-2.968, -147.52, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 57,
                s: [557.804, 950.975, 0],
                to: [2.507, 124.589, 0],
                ti: [-92.503, -0.545, 0],
              },
              { t: 67, s: [832, 1217.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 47,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 57,
                s: [50, 50, 100],
              },
              { t: 70, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 47,
        op: 87,
        st: 47,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 8,
        ty: 4,
        nm: "ellipse 24",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 43,
                s: [508, 745.5, 0],
                to: [-7, 219.667, 0],
                ti: [173.268, 12.932, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 63,
                s: [132, 1099.5, 0],
                to: [-243.768, -18.194, 0],
                ti: [47.833, 26.167, 0],
              },
              { t: 82, s: [-155, 942.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 43,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 53,
                s: [50, 50, 100],
              },
              { t: 82, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 43,
        op: 83,
        st: 43,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 9,
        ty: 4,
        nm: "ellipse 23",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 51,
                s: [504, 945.5, 0],
                to: [-163, 3.667, 0],
                ti: [164.154, 56.944, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 71,
                s: [132, 1099.5, 0],
                to: [-265.768, -92.194, 0],
                ti: [47.833, 62.5, 0],
              },
              { t: 90, s: [-155, 724.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 51,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 61,
                s: [100, 100, 100],
              },
              { t: 74, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 51,
        op: 91,
        st: 51,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 10,
        ty: 4,
        nm: "ellipse 22",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 41,
                s: [546, 731.5, 0],
                to: [-5.093, 89.221, 0],
                ti: [73.776, -93.658, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 52,
                s: [498.278, 1025.307, 0],
                to: [-48.333, 61.358, 0],
                ti: [149.262, 30.72, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 61,
                s: [164, 1127.5, 0],
                to: [-275.53, -56.708, 0],
                ti: [49.833, 70.833, 0],
              },
              { t: 80, s: [-135, 702.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 41,
                s: [0, 0, 100],
              },
              { t: 51, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 41,
        op: 81,
        st: 41,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 11,
        ty: 4,
        nm: "ellipse 21",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 35,
                s: [504, 945.5, 0],
                to: [141, 1.667, 0],
                ti: [-163.393, 59.091, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 55,
                s: [906, 1117.5, 0],
                to: [310.232, -112.194, 0],
                ti: [45.833, 199.833, 0],
              },
              { t: 74, s: [1159, 542.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 35,
                s: [0, 0, 100],
              },
              { t: 45, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 35,
        op: 75,
        st: 35,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 12,
        ty: 4,
        nm: "ellipse 20",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 39,
                s: [526, 603.5, 0],
                to: [-1, 175.667, 0],
                ti: [-151.601, -84.889, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 59,
                s: [734, 1107.5, 0],
                to: [78.232, 43.806, 0],
                ti: [-119.833, 33.167, 0],
              },
              { t: 78, s: [1089, 1132.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 39,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 49,
                s: [50, 50, 100],
              },
              { t: 62, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 39,
        op: 79,
        st: 39,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 13,
        ty: 4,
        nm: "ellipse 19",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 35,
                s: [508, 745.5, 0],
                to: [5, 103.667, 0],
                ti: [-101.901, -35.007, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 55,
                s: [686, 1053.5, 0],
                to: [182.033, 62.535, 0],
                ti: [-18.108, 150.248, 0],
              },
              { t: 74, s: [1117, 910.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 35,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 45,
                s: [50, 50, 100],
              },
              { t: 74, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 35,
        op: 75,
        st: 35,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 14,
        ty: 4,
        nm: "ellipse 18",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 39,
                s: [504, 945.5, 0],
                to: [149, 21.667, 0],
                ti: [-173.747, -1.025, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 59,
                s: [900, 1101.5, 0],
                to: [306.232, 1.806, 0],
                ti: [-15.833, 111.167, 0],
              },
              { t: 78, s: [1199, 686.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 39,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 49,
                s: [100, 100, 100],
              },
              { t: 62, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 39,
        op: 79,
        st: 39,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 15,
        ty: 4,
        nm: "ellipse 17",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 35,
                s: [508, 745.5, 0],
                to: [-7, 219.667, 0],
                ti: [173.268, 12.932, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 55,
                s: [132, 1099.5, 0],
                to: [-243.768, -18.194, 0],
                ti: [35.833, 40.833, 0],
              },
              { t: 74, s: [-83, 854.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 35,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 45,
                s: [50, 50, 100],
              },
              { t: 74, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 35,
        op: 75,
        st: 35,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 16,
        ty: 4,
        nm: "ellipse 16",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 39,
                s: [504, 945.5, 0],
                to: [-163, 3.667, 0],
                ti: [164.154, 56.944, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 59,
                s: [132, 1099.5, 0],
                to: [-265.768, -92.194, 0],
                ti: [41.833, 73.167, 0],
              },
              { t: 78, s: [-119, 660.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 39,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 49,
                s: [100, 100, 100],
              },
              { t: 62, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 39,
        op: 79,
        st: 39,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 17,
        ty: 4,
        nm: "ellipse 15",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 35,
                s: [504, 945.5, 0],
                to: [-163, 3.667, 0],
                ti: [164.154, 56.944, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 55,
                s: [132, 1099.5, 0],
                to: [-265.768, -92.194, 0],
                ti: [41.833, 73.167, 0],
              },
              { t: 74, s: [-119, 660.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 35,
                s: [0, 0, 100],
              },
              { t: 45, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 35,
        op: 75,
        st: 35,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 18,
        ty: 4,
        nm: "ellipse 14",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 26,
                s: [504, 945.5, 0],
                to: [141, 1.667, 0],
                ti: [-163.393, 59.091, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 46,
                s: [906, 1117.5, 0],
                to: [310.232, -112.194, 0],
                ti: [45.833, 199.833, 0],
              },
              { t: 65, s: [1159, 542.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 26,
                s: [0, 0, 100],
              },
              { t: 36, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 26,
        op: 66,
        st: 26,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 19,
        ty: 4,
        nm: "ellipse 13",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 30,
                s: [526, 603.5, 0],
                to: [-1, 175.667, 0],
                ti: [-151.601, -84.889, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 50,
                s: [734, 1107.5, 0],
                to: [78.232, 43.806, 0],
                ti: [-119.833, 33.167, 0],
              },
              { t: 69, s: [1089, 1132.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 30,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 40,
                s: [50, 50, 100],
              },
              { t: 53, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 30,
        op: 70,
        st: 30,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 20,
        ty: 4,
        nm: "ellipse 12",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 26,
                s: [508, 745.5, 0],
                to: [5, 103.667, 0],
                ti: [-101.901, -35.007, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 46,
                s: [686, 1053.5, 0],
                to: [182.033, 62.535, 0],
                ti: [-18.108, 150.248, 0],
              },
              { t: 65, s: [1117, 910.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 26,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 36,
                s: [99, 99, 100],
              },
              { t: 65, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 26,
        op: 66,
        st: 26,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 21,
        ty: 4,
        nm: "ellipse 11",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 30,
                s: [542, 617.5, 0],
                to: [-10.755, 107.393, 0],
                ti: [-2.968, -147.52, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 40,
                s: [557.804, 950.975, 0],
                to: [2.507, 124.589, 0],
                ti: [-92.503, -0.545, 0],
              },
              { t: 50, s: [796, 1205.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 30,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 40,
                s: [50, 50, 100],
              },
              { t: 53, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 30,
        op: 70,
        st: 30,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 22,
        ty: 4,
        nm: "ellipse 10",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 26,
                s: [508, 745.5, 0],
                to: [-7, 219.667, 0],
                ti: [173.268, 12.932, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 46,
                s: [132, 1099.5, 0],
                to: [-243.768, -18.194, 0],
                ti: [35.833, 40.833, 0],
              },
              { t: 65, s: [-83, 854.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 26,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 36,
                s: [50, 50, 100],
              },
              { t: 65, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 26,
        op: 66,
        st: 26,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 23,
        ty: 4,
        nm: "ellipse 9",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 34,
                s: [504, 945.5, 0],
                to: [-163, 3.667, 0],
                ti: [164.154, 56.944, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 54,
                s: [132, 1099.5, 0],
                to: [-265.768, -92.194, 0],
                ti: [41.833, 73.167, 0],
              },
              { t: 73, s: [-119, 660.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 34,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 44,
                s: [100, 100, 100],
              },
              { t: 57, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 34,
        op: 74,
        st: 34,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 24,
        ty: 4,
        nm: "ellipse 8",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 24,
                s: [546, 731.5, 0],
                to: [-5.093, 89.221, 0],
                ti: [73.776, -93.658, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 35,
                s: [498.278, 1025.307, 0],
                to: [-48.333, 61.358, 0],
                ti: [149.262, 30.72, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 44,
                s: [164, 1127.5, 0],
                to: [-275.53, -56.708, 0],
                ti: [47.167, 77.833, 0],
              },
              { t: 63, s: [-119, 660.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 24,
                s: [0, 0, 100],
              },
              { t: 34, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 24,
        op: 64,
        st: 24,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 25,
        ty: 4,
        nm: "ellipse 31",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 36,
                s: [524, 829.5, 0],
                to: [17, 165.667, 0],
                ti: [25.57, -56.073, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 56,
                s: [502, 1095.5, 0],
                to: [-70.167, 153.875, 0],
                ti: [91.5, 3.167, 0],
              },
              { t: 75, s: [-47, 1076.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 36,
                s: [0, 0, 100],
              },
              { t: 46, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 36,
        op: 76,
        st: 36,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 26,
        ty: 4,
        nm: "ellipse 30",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 29,
                s: [524, 829.5, 0],
                to: [17, 165.667, 0],
                ti: [25.57, -56.073, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 49,
                s: [502, 1095.5, 0],
                to: [-70.167, 153.875, 0],
                ti: [91.5, 3.167, 0],
              },
              { t: 68, s: [-47, 1076.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 29,
                s: [0, 0, 100],
              },
              { t: 39, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 29,
        op: 69,
        st: 29,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 27,
        ty: 4,
        nm: "ellipse 2",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 19,
                s: [504, 945.5, 0],
                to: [141, 1.667, 0],
                ti: [-163.393, 59.091, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 39,
                s: [906, 1117.5, 0],
                to: [310.232, -112.194, 0],
                ti: [45.833, 199.833, 0],
              },
              { t: 58, s: [1159, 542.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 19,
                s: [0, 0, 100],
              },
              { t: 29, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 19,
        op: 59,
        st: 19,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 28,
        ty: 4,
        nm: "ellipse 7",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 23,
                s: [526, 603.5, 0],
                to: [-1, 175.667, 0],
                ti: [-151.601, -84.889, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 43,
                s: [734, 1107.5, 0],
                to: [78.232, 43.806, 0],
                ti: [-119.833, 33.167, 0],
              },
              { t: 62, s: [1089, 1132.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 23,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 33,
                s: [50, 50, 100],
              },
              { t: 46, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 23,
        op: 63,
        st: 23,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 29,
        ty: 4,
        nm: "ellipse 5",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 19,
                s: [508, 745.5, 0],
                to: [5, 103.667, 0],
                ti: [-101.901, -35.007, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 39,
                s: [686, 1053.5, 0],
                to: [182.033, 62.535, 0],
                ti: [-18.108, 150.248, 0],
              },
              { t: 58, s: [1117, 910.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 19,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 29,
                s: [50, 50, 100],
              },
              { t: 58, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 19,
        op: 59,
        st: 19,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 30,
        ty: 4,
        nm: "ellipse 6",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 23,
                s: [504, 945.5, 0],
                to: [149, 21.667, 0],
                ti: [-173.747, -1.025, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 43,
                s: [900, 1101.5, 0],
                to: [306.232, 1.806, 0],
                ti: [-15.833, 111.167, 0],
              },
              { t: 62, s: [1199, 686.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 23,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 33,
                s: [100, 100, 100],
              },
              { t: 46, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 23,
        op: 63,
        st: 23,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 31,
        ty: 4,
        nm: "ellipse 4",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 19,
                s: [508, 745.5, 0],
                to: [-7, 219.667, 0],
                ti: [173.268, 12.932, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 39,
                s: [132, 1099.5, 0],
                to: [-243.768, -18.194, 0],
                ti: [35.833, 40.833, 0],
              },
              { t: 58, s: [-83, 854.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 19,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 29,
                s: [50, 50, 100],
              },
              { t: 58, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 19,
        op: 59,
        st: 19,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 32,
        ty: 4,
        nm: "ellipse 3",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 23,
                s: [504, 945.5, 0],
                to: [-163, 3.667, 0],
                ti: [164.154, 56.944, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 43,
                s: [132, 1099.5, 0],
                to: [-265.768, -92.194, 0],
                ti: [41.833, 73.167, 0],
              },
              { t: 62, s: [-119, 660.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 23,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 33,
                s: [100, 100, 100],
              },
              { t: 46, s: [67, 67, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 23,
        op: 63,
        st: 23,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 33,
        ty: 4,
        nm: "ellipse 29",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 19,
                s: [524, 829.5, 0],
                to: [17, 165.667, 0],
                ti: [25.57, -56.073, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 39,
                s: [502, 1095.5, 0],
                to: [-70.167, 153.875, 0],
                ti: [91.5, 3.167, 0],
              },
              { t: 58, s: [-47, 1076.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 19,
                s: [0, 0, 100],
              },
              { t: 29, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 19,
        op: 59,
        st: 19,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 34,
        ty: 4,
        nm: "ellipse",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 19,
                s: [504, 945.5, 0],
                to: [-163, 3.667, 0],
                ti: [164.154, 56.944, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 39,
                s: [132, 1099.5, 0],
                to: [-265.768, -92.194, 0],
                ti: [41.833, 73.167, 0],
              },
              { t: 58, s: [-119, 660.5, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-2, 537.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 19,
                s: [0, 0, 100],
              },
              { t: 29, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [216, 216], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862804936, 0.901960844152, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [-2, 537.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 19,
        op: 59,
        st: 19,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 35,
        ty: 4,
        nm: "line 3",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [586, 351, 0], ix: 2 },
          a: { a: 0, k: [268, 73, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [268, 218],
                      [268, 352],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.078431372549, 0.258823529412, 0.619607843137, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "tm",
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.35], y: [1] },
                  o: { x: [0.65], y: [0] },
                  t: 63,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 57,
                  s: [0],
                },
                { t: 69, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: "Trim Paths 1",
            mn: "ADBE Vector Filter - Trim",
            hd: false,
          },
        ],
        ip: 57,
        op: 75,
        st: -1,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 36,
        ty: 4,
        nm: "line 4",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [586, 531, 0], ix: 2 },
          a: { a: 0, k: [268, 73, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [268, 218],
                      [268, 352],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.078431372549, 0.258823529412, 0.619607843137, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "tm",
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.35], y: [1] },
                  o: { x: [0.65], y: [0] },
                  t: 60,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 54,
                  s: [0],
                },
                { t: 69, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: "Trim Paths 1",
            mn: "ADBE Vector Filter - Trim",
            hd: false,
          },
        ],
        ip: 54,
        op: 75,
        st: -1,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 37,
        ty: 4,
        nm: "line 2",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [446, 427, 0], ix: 2 },
          a: { a: 0, k: [268, 73, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [268, 218],
                      [268, 352],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: {
                  a: 0,
                  k: [0.078431372549, 0.258823529412, 0.619607843137, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
          {
            ty: "tm",
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.35], y: [1] },
                  o: { x: [0.65], y: [0] },
                  t: 63,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 57,
                  s: [0],
                },
                { t: 69, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: "Trim Paths 1",
            mn: "ADBE Vector Filter - Trim",
            hd: false,
          },
        ],
        ip: 57,
        op: 75,
        st: -1,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 38,
        ty: 4,
        nm: "Shape Layer 2",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [540, 298, 0], ix: 2 },
          a: { a: 0, k: [104, -60, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                o: { x: [0, 0, 0.333], y: [0, 0, 0] },
                t: 14,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.13, 0.13, 0.667], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                t: 34,
                s: [80, 80, 100],
              },
              {
                i: { x: [0.13, 0.13, 0.667], y: [1, 1, 1] },
                o: { x: [0.87, 0.87, 0.333], y: [0, 0, 0] },
                t: 67,
                s: [80, 80, 100],
              },
              { t: 75, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [556, 556], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.756862745098, 0.903698610792, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [104, -60], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 14,
        op: 264,
        st: 14,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 39,
        ty: 4,
        nm: "Shape Layer 1",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [540, 298, 0], ix: 2 },
          a: { a: 0, k: [104, -60, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                o: { x: [0, 0, 0.333], y: [0, 0, 0] },
                t: 12,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.13, 0.13, 0.667], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                t: 32,
                s: [100, 100, 100],
              },
              {
                i: { x: [0.13, 0.13, 0.667], y: [1, 1, 1] },
                o: { x: [0.87, 0.87, 0.333], y: [0, 0, 0] },
                t: 62,
                s: [100, 100, 100],
              },
              { t: 72, s: [0, 0, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [556, 556], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.864052267636, 0.946154067096, 1, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [104, -60], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 12,
        op: 262,
        st: 12,
        bm: 0,
      },
    ],
    markers: [{ tm: 0, cm: "1", dr: 0 }],
  }

