import * as React from "react"
import { DATA } from "../test"
import { Suspense } from "react"
const Player = React.lazy(() =>
  import("@lottiefiles/react-lottie-player").then(module => {
    return { default: module.Player }
  })
)

const Controls = React.lazy(() =>
  import("@lottiefiles/react-lottie-player").then(module => {
    return { default: module.Controls }
  })
)
export default function Component(props) {
  const isSSR = typeof window === "undefined"
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <Player
            autoplay
            loop
            src={
              "https://images.primeagile.com/demo_primeagile_com/application/12201/test.json"
            }
            style={{ height: "300px", width: "300px" }}
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
        </Suspense>
      )}
    </>
  )
}
